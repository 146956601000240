import { createTheme } from '@mui/material/styles';

export function muiTheme() {

    // Defining the theme.
    var theme = createTheme({
        palette: {
            text: {
                disabled: "black",
            },
        },
    })

    // Returning the theme
    return theme;

}