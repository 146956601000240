// Various import statements
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, TextField, Chip, Popper, Typography, Autocomplete, MenuItem, IconButton, Paper } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import "./style.css"
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import debounce from "lodash/debounce"

// Defining the DSMultiselectHooks example
function DSMultiselectHooks(props) {

    // Indicating that the options will appear in a state; I'm trying to emulate this solution:
    // https://stackoverflow.com/questions/40811535/delay-suggestion-time-in-mui-autocomplete
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [debounceTime, setDebounceTime] = useState(0)
    const [selectedValue, setSelectedValue] = useState(props.value)
    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            setOptions([]);
            callback(getOptions(text));
        }, props.optionLoadDebounceTime),
        []
    )

    useEffect(() => {
        getOptionsDelayed(inputValue, (filteredOptions) => {
            setOptions(filteredOptions)
        })
    }, [inputValue, getOptionsDelayed])

    useEffect(() => {
        setSelectedValue(props.value)
    }, [JSON.stringify(props.value)])

    // This function will retrieve the relevant options from props.options when given a particular set of text
    function getOptions(text) {

        if (!text | (selectedValue != undefined & props.options.map((option)=>{return option.label}).includes(text))) {
            return props.options
        }
        var searchResults = []
        var specialSearchResults = []
        props.options.forEach(function(option) {
            const text_lowerCase = text.toLowerCase()
            const option_lowerCase = option.label.toLowerCase()
            if (option_lowerCase.includes(text_lowerCase) || option_lowerCase.toLowerCase()===text_lowerCase) {
                if (option_lowerCase.startsWith(text_lowerCase)) {
                    specialSearchResults.push(option)
                }
                else{
                    searchResults.push(option)
                }
            }
        })
        return specialSearchResults.concat(searchResults)

    }
    
    function getTruncatedOptions(cur_options) {

        // Truncate the labels
        var truncatedOptions = JSON.parse(JSON.stringify(cur_options))
        if (props.label_limit != -1) {
            truncatedOptions = truncatedOptions.map(x => {
                var return_options_dict = {"value": x.value, "label": x.label, "truncated_label": truncateLabel(x.label)}
                if ("tag_label" in x) {
                    return_options_dict["tag_label"] = x.tag_label
                }
                return return_options_dict
            })
        }

        return truncatedOptions
    }

    // This function will truncate any labels according to the label_limit prop
    function truncateLabel(label) {

        // If there's an actual label_limit in the props (and it's not -1, which indicates 'use the full label'), then
        // we're going to apply the label limit
        if ("label_limit" in props & props.label_limit !== -1 & label.length > props.label_limit) {
            var truncatedLabel = label.substring(0, props.label_limit) + "..."
            return truncatedLabel
        }

        // Otherwise, we'll just return the original label
        return label
    }

    // This function will return the React component we want to use as a popup icon
    function getPopupIcon() {

        // If the component isn't disabled, then we'll return our custom dropdown arrow
        if (!props.disabled) {
            return (
                <ArrowBackIosNewSharpIcon sx={{paddingTop: "8px", transform: "rotate(-90deg)", marginRight: "5px"}}/>
            )
        }

        // If we ARE disabled, then we don't want to actually return anything
        return ""
    }

    function findCorrespondingOptionLabel(option) {
        for (const cur_option of props.options) {
            if (cur_option["value"] === option) {
                return cur_option["label"]
            }
        }
        return option
    }

    // Extracting the "width" and "height" arguments from the style prop (if they're there)
    var filter_style = {"width": ("width" in props.style ? props.style["width"] : 300)}
    var filter_height = "height" in props.style ? props.style["height"] : 55
    filter_style["height"] = filter_height

    // Creating a labelStyle Object, which will help to style the filter label
    var labelStyle = "label_style" in props.style ? JSON.parse(JSON.stringify(props.style["label_style"])) : {}
    labelStyle["marginTop"] = 13  // This will keep the label inside of the Select filter

    // Creating a valueStyle Object (and valueListStyle Object), which will style the selected values and the
    // values listed when a user expands the filter
    var valueStyle = "value_style" in props.style ? JSON.parse(JSON.stringify(props.style["value_style"])) : {}
    var valueListStyle = "value_style" in props.style ? JSON.parse(JSON.stringify(props.style["value_style"])) : {}
    valueStyle["paddingTop"] = "0px"
    valueStyle["paddingBottom"] = "0px"

    // Decide whether or not we'll include the "limitTags" prop
    var limitTags_ct = props.tag_limit
    if (props.summary) {
        limitTags_ct = -1
    }

    // Preparing the ListBox Props
    var listbox_props = {}
    if ("dropdown_style" in props.style) {
        listbox_props = JSON.parse(JSON.stringify(props.style.dropdown_style))
    }

    return (
        // Wrap the whole filter in a Div
        <div id={props.id + "_div_container"} style={filter_style}>

            {/* We'll wrap the Autocomplete in a FormControl */}
            <FormControl fullWidth>

                {/* This is the Autocomplete component that serves as the basis for the Select */}
                <Autocomplete
                 multiple
                 onInputChange={(e, newInputValue) => {
                    setInputValue(newInputValue)
                 }}
                 limitTags={limitTags_ct}
                 id={props.id + "_select"}
                 options={getTruncatedOptions(options)}
                 fullWidth={true}
                 value={(selectedValue === "" || selectedValue === null) ? [] : selectedValue}
                 defaultValue={selectedValue === "" ? [] : selectedValue}
                 getOptionLabel={(option) => {

                    if (typeof option === "string") {
                        return findCorrespondingOptionLabel(option)
                    }

                    return option["label"] || ""}
                }
                 disabled={props.disabled}
                 readOnly={props.disabled}
                 disableClearable={!props.clearable}

                 // This function will determine whether or not an option is equal to a value
                 isOptionEqualToValue={(option, value) => {
                    if (typeof value === "string") {
                        return option.value===value
                    }
                    else {
                        return option.value===value.value
                    }
                 }}

                 ListboxProps={{style: listbox_props}}

                 // Specify the way that the Autocomplete's value is rendered
                 renderInput={(params) => {

                    // This will edit the "InputProps" property of the TextField.
                    var parent_InputProps = params["InputProps"]
                    parent_InputProps["notched"] = false
                    parent_InputProps["variant"] = "outlined"
                    parent_InputProps["style"] = {"paddingTop": 20, "paddingBottom": 5, "minHeight": filter_height}

                    // This will edit the "inputProps" property of the TextField.
                    // (I know the name is confusing, but that's how MUI does it.)
                    var parent_inputProps = params["inputProps"]
                    parent_inputProps["style"] = valueStyle
                    parent_InputProps["onMouseDown"] = parent_inputProps["onMouseDown"]

                    return(
                        <TextField {...params}
                         label={props.label}
                         InputProps={parent_InputProps}
                         inputProps={parent_inputProps}
                         InputLabelProps={{style: labelStyle, shrink: true}}
                        />
                    )
                 }}

                 // Specify the Popper component
                 PopperComponent={(props) => {

                    // Define some styling for the Popper. It'll be the width of the filter, unless there's
                    // a dropdown_style specified
                    const dropdown_style = {"width": "100%", "zIndex": 300000}
                    if ("dropdown_style" in props.style && "width" in props.style["dropdown_style"]) {
                        const width_str = JSON.stringify(props.style["dropdown_style"]["width"])
                        if (!width_str.endsWith("%")) {
                            dropdown_style["width"] = props.style["dropdown_style"]["width"]
                        }
                    }

                    // Since I've overridden some of the default behavior of the Popper, I need to specify
                    // that
                    if (props.disabled) {
                        return(<></>)
                    }

                    return(
                        <Popper {...props} style={dropdown_style} placement="bottom-start" disablePortal={true}/>
                    )
                 }}

                 // Specify what happens when the user makes a selection
                 onChange={(event, value, reason) => {

                    // If we're clearing the input, we need to set the value prop to a blank string,
                    // AND increment the clear_button_n_clicks prop
                    if (reason === "clear") {
                        const new_clear_button_n_clicks = props.clear_button_n_clicks + 1
                        setSelectedValue([])
                        var setPropsDebounced = debounce(function() {
                            props.setProps({value: [], clear_button_n_clicks: new_clear_button_n_clicks})
                        }, props.valueChangeDebounceTime)
                        setPropsDebounced()
                    }

                    // Otherwise, if we've selected an input, set the value prop to that value
                    else if (reason === "selectOption" || reason === "removeOption") {

                        const curValueArray = value.map(x => {
                            if (typeof x === "string") {return x}
                            return x.value
                        })
                        setSelectedValue(curValueArray)
                        var setPropsDebounced = debounce(function() {
                            props.setProps({value: curValueArray})
                        }, props.valueChangeDebounceTime)
                        setPropsDebounced()
                    }

                 }}

                 renderTags={(value, getTagProps) => {
                    if (!props.summary) {
                        return value.map((option, index) => {
                            var curChipStyle = valueListStyle
                            var optionLabel = option.truncated_label
                            if (typeof option === "string") {
                                optionLabel = findCorrespondingOptionLabel(option)
                            }
                            else if (typeof option === "object" && "tag_label" in option) {
                                optionLabel = option.tag_label
                            }
                            else if (typeof option === "object" && "label" in option) {
                                optionLabel = option.label
                            }
                            var extraProps = {}
                            if (!props.clearable) {
                                var extraProps = {"deleteIcon": <div/>}
                            }

                            var chipProps = getTagProps({ index })
                            if (props.disabled) {
                                chipProps["disabled"] = false
                                chipProps["deleteIcon"] = <div/>
                            }

                            return(
                                <Chip
                                 label={optionLabel}
                                 size="small"
                                 {...chipProps}
                                 style={curChipStyle}/>
                        )})
                    }
                    else {
                        if (value.length < props.summary_threshold) {
                            return value.map((option, index) => {
                                var curChipStyle = valueListStyle
                                var optionLabel = option.truncated_label
                                if (typeof option === "string") {
                                    optionLabel = findCorrespondingOptionLabel(option)
                                }
                                else if (typeof option === "object" && "tag_label" in option) {
                                    optionLabel = option.tag_label
                                }
                                var extraProps = {}
                                if (!props.clearable) {
                                    var extraProps = {"deleteIcon": <div/>}
                                }

                                var chipProps = getTagProps({ index })
                                if (props.disabled) {
                                    chipProps["disabled"] = false
                                    chipProps["deleteIcon"] = <div/>
                                }

                                return(
                                    <Chip
                                     size="small"
                                     label={optionLabel}
                                     {...chipProps}
                                     style={curChipStyle}/>
                                )
                            })
                        }
                        return <div style={{"marginLeft": "7px"}}>{JSON.stringify(value.length) + " selected"}</div>
                    }
                 }

                 }

                 // Below, we're going to specify the look of the dropdown button
                 popupIcon={getPopupIcon()}

                 // Specify some of the styling of the overall Autocomplete object
                 style={{paddingRight: 0, paddingBottom: 0, paddingTop: 0}}
                />

            </FormControl>

        </div>
    )



};

// Defining the default properties for the DSMultiselectHooks component
DSMultiselectHooks.defaultProps = {
    id: "ds_filter",
    options: [],
    value: [],
    filter_type: "multiselect",
    disabled: false,
    clearable: true,
    style: {width: 300, height: 55, value_style: {}, label_style: {}}
};

// Defining the different property types for the DSMultiselectHooks component
DSMultiselectHooks.propTypes = {

    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The type of the filter
     */
    options: PropTypes.array,

    /**
     * The currently selected options for the filter
     */
    value: PropTypes.array,

    /**
     * The type of the filter
     */
    filter_type: PropTypes.string,

    /**
     * Whether or not this filter is disabled
     */
    disabled: PropTypes.bool,

    /**
     * Various styling elements that're applied to the filter component
     */
     style: PropTypes.object,

    /**
     * Whether or not this filter is clearable
     */
    clearable: PropTypes.bool,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};

// Exporting the defaultProps and propTypes objects for use in the React-Dash bridge
export const defaultProps = DSMultiselectHooks.defaultProps;
export const propTypes = DSMultiselectHooks.propTypes;


// Exporting this component for use in other components
export default DSMultiselectHooks;

