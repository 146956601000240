/* eslint-disable no-magic-numbers */
/* eslint-disable no-inline-comments */
/* eslint-disable eqeqeq */
/* eslint-disable no-undefined */
// Various import statements
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Popper, TextField, Autocomplete } from '@mui/material';
import "./style.css"
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import debounce from "lodash/debounce"

// Defining the DSSelectHooks example
function DSSelectHooks(props) {

    // Indicating that the options will appear in a state; I'm trying to emulate this solution:
    // https://stackoverflow.com/questions/40811535/delay-suggestion-time-in-mui-autocomplete
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState(props.value)
    const getOptionsDelayed = useCallback(
        debounce((text, callback) => {
            setOptions([]);
            callback(getOptions(text));
        }, 0),
        []
    )

    useEffect(() => {
        setSelectedValue(props.value)
    }, [JSON.stringify(props.value)])

    useEffect(() => {
        getOptionsDelayed(inputValue, (filteredOptions) => {
            setOptions(filteredOptions)
        })
    }, [inputValue, getOptionsDelayed])

    useEffect(() => {
        setOptions(getOptions())
    }, [JSON.stringify(props.options)])

    // This function will retrieve the relevant options from props.options when given a particular set of text
    function getOptions(text) {

        console.log("Running getOptions with text: ", text)
        console.log("Props are: ", props)

        if (!text | (selectedValue != undefined & props.options.map((option)=>{return option.label}).includes(text))) {
            console.log("In getOptions, returning props.options, which is: ", props.options)
            return props.options
        }
        var searchResults = []
        var specialSearchResults = []
        props.options.forEach(function(option) {
            const text_lowerCase = text.toLowerCase()
            const option_lowerCase = option.label.toLowerCase()
            if (option_lowerCase.includes(text_lowerCase) || option_lowerCase.toLowerCase()===text_lowerCase) {
                if (option_lowerCase.startsWith(text_lowerCase)) {
                    specialSearchResults.push(option)
                }
                else{
                    searchResults.push(option)
                }
            }
        })
        console.log("In getOptions, returning specialSearchResults.concat(searchResults), which is:", specialSearchResults.concat(searchResults))
        return specialSearchResults.concat(searchResults)

    }

    // This function will return the React component we want to use as a popup icon
    function getPopupIcon() {

        // If the component isn't disabled, then we'll return our custom dropdown arrow
        if (!props.disabled && !(props.options.length === 1)) {
            return (
                <ArrowBackIosNewSharpIcon sx={{paddingTop: "8px", transform: "rotate(-90deg)", marginRight: "5px"}}/>
            )
        }

        // If we ARE disabled, then we don't want to actually return anything
        return ""
    }

    // Extracting the "width" and "height" arguments from the style prop (if they're there)
    var filter_style = {"width": ("width" in props.style ? props.style.width : 300)}
    var filter_height = "height" in props.style ? props.style.height : 55

    // Creating a labelStyle Object, which will help to style the filter label
    var labelStyle = "label_style" in props.style ? JSON.parse(JSON.stringify(props.style.label_style)) : {}
    labelStyle.marginTop = 15  // This will keep the label inside of the Select filter

    // Creating a valueStyle Object (and valueListStyle Object), which will style the selected values and the
    // values listed when a user expands the filter
    var valueStyle = "value_style" in props.style ? JSON.parse(JSON.stringify(props.style.value_style)) : {}
    valueStyle.marginTop = 5  // This will separate the value from the label, since I've repositioned it

    // Preparing the ListBox Props
    var listbox_props = {}
    if ("dropdown_style" in props.style) {
        listbox_props = JSON.parse(JSON.stringify(props.style.dropdown_style))
    }

    // Determine the current list of options
    console.log("props.options: ", props.options)
    console.log("options: ", options)

    return (

        // Wrap the whole filter in a Div
        <div id={props.id + "_div_container"} style={filter_style}>

            {/* We'll wrap the Autocomplete in a FormControl */}
            <FormControl fullWidth>

                {/* This is the Autocomplete component that serves as the basis for the Select */}
                <Autocomplete
                 id={props.id + "_select"}
                 autoComplete={true}
                 options={options}
                 classes={{"color": "red"}}
                 getOptionLabel={(option) => {
                    if (typeof option === "string") {
                        for (const cur_option of props.options) {
                            if (cur_option.value === option) {
                                return cur_option.label
                            }
                        }
                    }
                    return option.label || ""}
                 }
                 disabled={props.disabled}
                 disableClearable={!props.clearable || props.value === undefined || props.value === ""}
                 readOnly={props.options.length === 1 && !props.clearable}
                 value={selectedValue}
                 fullWidth={true}
                 ListboxProps={{style: listbox_props}}
                 filterOptions={(x)=>x}
                 onInputChange={(e, newInputValue) => {
                    setInputValue(newInputValue)
                 }}

                 // Specify the Popper component
                 PopperComponent={(props) => {

                    // Define some styling for the Popper. It'll be the width of the filter, unless there's
                    // a dropdown_style specified
                    const dropdown_style = {"width": "100%", "zIndex": 300000}
                    if ("dropdown_style" in props.style && "width" in props.style.dropdown_style) {
                        const width_str = JSON.stringify(props.style.dropdown_style.width)
                        if (!width_str.endsWith("%")) {
                            dropdown_style.width = props.style.dropdown_style.width
                        }
                    }

                    // Since I've overridden some of the default behavior of the Popper, I need to specify
                    // that
                    if (props.disabled) {
                        return(<></>)
                    }

                    return(
                        <Popper {...props} style={dropdown_style} placement="bottom-start" disablePortal={true}/>
                    )
                 }}

                 // This function will determine whether or not an option is equal to a value
                 isOptionEqualToValue={(option, value) => {
                    if (typeof value === "string") {
                        return option.value===value
                    }
                    
                        return option.value===value.value
                    
                 }}

                 // Specify the way that the Autocomplete's value is rendered
                 renderInput={(params) => {

                    // This will edit the "InputProps" property of the TextField.
                    var parent_InputProps = params.InputProps
                    parent_InputProps.notched = false
                    parent_InputProps.variant = "outlined"
                    parent_InputProps.style = {"height": filter_height}

                    // This will edit the "inputProps" property of the TextField.
                    // (I know the name is confusing, but that's how MUI does it.)
                    var parent_inputProps = params.inputProps
                    parent_inputProps.style = valueStyle

                    return(
                        <TextField {...params}
                         label={props.label}
                         InputProps={parent_InputProps}
                         inputProps={parent_inputProps}
                         InputLabelProps={{style: labelStyle, shrink: true}}
                        />
                    )
                 }}

                 // Specify what happens when the user makes a selection
                 onChange={(event, value, reason) => {

                    setInputValue("")

                    // If we're clearing the input, we need to set the value prop to a blank string,
                    // AND increment the clear_button_n_clicks prop
                    if (reason === "clear") {
                        const new_clear_button_n_clicks = props.clear_button_n_clicks + 1
                        setSelectedValue(undefined)
                        var setPropsDebounced = debounce(function() {
                            props.setProps({value: undefined, clear_button_n_clicks: new_clear_button_n_clicks})
                        }, props.valueChangeDebounceTime)
                        setPropsDebounced()
                    }

                    // Otherwise, if we've selected an input, set the value prop to that value
                    else {
                        setSelectedValue(value.value)
                        var setPropsDebounced = debounce(function() {
                            props.setProps({value: value.value})
                        }, props.valueChangeDebounceTime)
                        setPropsDebounced()

                    }
                 }}

                 // Below, we're going to specify the look of the dropdown button
                 popupIcon={getPopupIcon()}

                 // Specify some of the styling of the overall Autocomplete object
                 style={{paddingRight: 0, paddingBottom: 0, paddingTop: 0}}
                />

            </FormControl>

        </div>
    )

};

// Defining the default properties for the DSSelect component
DSSelectHooks.defaultProps = {
    id: "ds_filter",
    options: [],
    value: "",
    filter_type: "select",
    disabled: false,
    clearable: true,
    style: {width: 300, height: 55, value_style: {}, label_style: {}}
};

// Defining the different property types for the DSSelect component
DSSelectHooks.propTypes = {

    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The type of the filter
     */
    options: PropTypes.array,

    /**
     * The currently selected options for the filter
     */
    value: PropTypes.string,

    /**
     * For programmatically updating the value of the filter
     */
    valueUpdate: PropTypes.string,

    /**
     * The type of the filter
     */
    filter_type: PropTypes.string,

    /**
     * Whether or not this filter is disabled
     */
    disabled: PropTypes.bool,

    /**
     * Various styling elements that're applied to the filter component
     */
     style: PropTypes.object,

    /**
     * Whether or not this filter is clearable
     */
    clearable: PropTypes.bool,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};

// Exporting the defaultProps and propTypes objects for use in the React-Dash bridge
export const defaultProps = DSSelectHooks.defaultProps;
export const propTypes = DSSelectHooks.propTypes;


// Exporting this component for use in other components
export default DSSelectHooks;

