// Various import statements that I'll use throughout the component
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, ClickAwayListener, InputAdornment, MenuItem, IconButton, TextField, Box, Item } from '@mui/material';
import "./style.css"
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


/**
 * DSSelect is a multi-purpose filtering component.
 */
export default class DSSelect extends Component {

    // LIFECYCLE - The constructor will set up pieces of the component before it's mounted into the DOM
    constructor(props) {

        // Run the constructor of the general Component
        super(props)

        // Check to see if the start_date and end_date are in the props
        var start_date = null
        var end_date = null
        var min_date = null
        var max_date = null
        var props_start_date = this.props.start_date
        var props_end_date = this.props.end_date
        if ("start_date" in this.props) {
            start_date = this.props.start_date
            start_date = new Date(start_date, 1, 1)
        }
        if ("end_date" in this.props) {
            end_date = this.props.end_date
            end_date = new Date(end_date, 1, 1)
        }
        if ("min_selectable_year" in this.props) {
            min_date = this.props.min_selectable_year
            min_date = new Date(min_date, 1, 1)
        }

        if ("max_selectable_year" in this.props) {
            max_date = this.props.max_selectable_year
            max_date = new Date(max_date, 1, 1)
        }


        // Set the state according to some initial values
        this.state = {
            "start_date": start_date,
            "end_date": end_date,
            "start_open": false,
            "end_open": false,
            "min_date": min_date,
            "max_date": max_date
        }

        // Set the props
        this.props.setProps({ value: [props_start_date, props_end_date] })

    }

    // LIFECYCLE - This method is run immediately after a component is inserted into the DOM
    componentDidMount() {

    }

    // LIFECYCLE - This method will determine whether or not the component should update (according to the different
    // props / state that it'll have after the next update)
    shouldComponentUpdate(nextProps, nextState) {

        // By default, we're going to just return "true" - this will allow the component to render normally. If we
        // realize that we need more specialized behavior, then we'll add it. For now, though, this works.
        return true

    }

    // LIFECYCLE - This method will run immediately after updating occurs; it's not run after the first render.
    componentDidUpdate(prevProps) {

        // If the start_date or end_date have changed, then we'll update the state
        // if (this.props.start_date !== prevProps.start_date) {
        //     this.setState({ "start_date": new Date(this.props.start_date, 1, 1) })
        //     if (this.props.value[0] !== this.props.start_date) {
        //         this.props.setProps({ value: [this.props.start_date, this.props.value[1]] })
        //     }
        // }
        // if (this.props.end_date !== prevProps.end_date) {
        //     this.setState({ "end_date": new Date(this.props.end_date, 1, 1) })
        //     if (this.props.value[1] !== this.props.end_date) {
        //         this.props.setProps({ value: [this.props.value[0], this.props.end_date] })
        //     }
        // }
        // if (this.props.value[0] !== prevProps.start_date) {
        //     this.setState({ "start_date": new Date(this.props.value[0], 1, 1) })
        // }
        // if (this.props.value[1] !== prevProps.end_date) {
        //     this.setState({ "end_date": new Date(this.props.value[1], 1, 1) })
        // }
        if (this.props.min_selectable_year !== prevProps.min_selectable_year) {
            this.setState({ "min_date": new Date(this.props.min_selectable_year, 1, 1) })
        }
        if (this.props.max_selectable_year !== prevProps.max_selectable_year) {
            this.setState({ "max_date": new Date(this.props.max_selectable_year, 1, 1) })
        }

    }

    dateToYear(date) {
        if (date === null) {
            return date
        }
        return date.getFullYear()
    }


    // LIFECYCLE - The render method determines what'll be rendered by the DSSelect component
    render() {

        const options = this.props.options
        var style_options = { "display": "inline-block" }

        // Setting the filter's width
        var filter_width = 300
        if ("width" in this.props.style) {
            filter_width = this.props.style["width"]
        }
        style_options["width"] = filter_width

        // Setting the filter's height
        var filter_height = 55
        if ("height" in this.props.style) {
            filter_height = this.props.style["height"]
        }

        // Dealing with the style of the label
        var labelStyle = {}
        if ("label_style" in this.props.style) { labelStyle = JSON.parse(JSON.stringify(this.props.style["label_style"])) }
        labelStyle["marginTop"] = 15

        // Dealing with the style of the value
        var valueStyle = {}
        if ("value_style" in this.props.style) { valueStyle = JSON.parse(JSON.stringify(this.props.style["value_style"])) }

        
        // Determine what the value ought to be 
        var start_value = this.props.value[0]

        return (

            <div style={style_options}>

                <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", columnGap: "10px" }}>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disabled={this.props.disabled}
                            open={this.state.start_open}
                            views={["year"]}
                            sx={{ display: "inline" }}
                            value={this.props.value[0] !== null ? new Date(this.props.value[0], 1, 1) : null}
                            minDate={this.state.min_date}
                            maxDate={this.state.max_date}
                            onChange={(newValue) => {
                                // this.setState({ start_date: newValue })
                                this.props.setProps({
                                    value: [this.dateToYear(newValue),
                                        this.props.value[1]]
                                })
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => {
                                inputProps["style"] = { "marginTop": 15, "borderColor": "rgb(255, 0, 0)" }
                                inputProps["onClick"] = () => { this.setState({ "start_open": true }) }
                                return (
                                    <ClickAwayListener onClickAway={() => this.setState({ "start_open": false })}>
                                        <TextField fullWidth
                                            autoComplete="off"
                                            variant="outlined"
                                            inputProps={inputProps}
                                            inputRef={inputRef}
                                            label={(typeof this.props.label === "string") ? "Start year" : this.props.label[0]}
                                            InputProps={{
                                                notched: false,
                                                endAdornment:
                                                    !this.props.disabled &&
                                                    <InputAdornment position="end" style={{ "position": "absolute", 'right': 12 }}>

                                                        {this.props.value[0] !== null && this.props.clearable &&
                                                            <IconButton size="small" onClick={() => {
                                                                const new_clear_button_clicks = this.props.clear_button_n_clicks + 1
                                                                // this.setState({ start_date: null })
                                                                this.props.setProps({
                                                                    value: [null,
                                                                        this.props.value[1]],
                                                                    clear_button_n_clicks: new_clear_button_clicks
                                                                })
                                                            }}
                                                                style={{ "position": "absolute", "right": -8 }}>
                                                                <CloseOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>,
                                                sx: { height: filter_height },
                                                style: valueStyle,
                                                className: "value_style"
                                            }}
                                            InputLabelProps={{ shrink: true, style: labelStyle, className: "label_style" }}
                                            helperText={null} />
                                    </ClickAwayListener>)
                            }} />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disabled={this.props.disabled}
                            views={["year"]}
                            open={this.state.end_open}
                            style={{ "&:hover": { backgroundColor: "red" } }}
                            minDate={this.state.min_date}
                            maxDate={this.state.max_date}
                            sx={{ display: "inline" }}
                            value={this.props.value[1] !== null ? new Date(this.props.value[1], 1, 1) : null}
                            onChange={(newValue) => {
                                // this.setState({ end_date: newValue })
                                this.props.setProps({
                                    value: [this.props.value[0],
                                    this.dateToYear(newValue)]
                                })
                            }}
                            renderInput={({ inputRef, inputProps, InputProps }) => {
                                inputProps["style"] = { "marginTop": 15 }
                                inputProps["onClick"] = () => { this.setState({ "end_open": true }) }
                                return (
                                    <ClickAwayListener onClickAway={() => this.setState({ "end_open": false })}>
                                        <TextField fullWidth
                                            autoComplete="off"
                                            variant="outlined"
                                            inputProps={inputProps}
                                            inputRef={inputRef}
                                            label={typeof this.props.label === "string" ? "End year" : this.props.label[1]}
                                            InputProps={{
                                                notched: false,
                                                endAdornment:
                                                    !this.props.disabled &&
                                                    <InputAdornment position="end" style={{ "position": "absolute", 'right': 12 }}>

                                                        {this.props.value[1] !== null && this.props.clearable &&
                                                            <IconButton size="small" onClick={() => {
                                                                const new_clear_button_clicks = this.props.clear_button_n_clicks + 1
                                                                // this.setState({ end_date: null })
                                                                this.props.setProps({
                                                                    value: [this.props.value[0],
                                                                        null],
                                                                    clear_button_n_clicks: new_clear_button_clicks
                                                                })
                                                            }}
                                                                style={{ "position": "absolute", "right": -8 }}>
                                                                <CloseOutlinedIcon fontSize="small" />
                                                            </IconButton>
                                                        }
                                                    </InputAdornment>,
                                                sx: { height: filter_height },
                                                style: valueStyle,
                                                className: "value_style"
                                            }}
                                            InputLabelProps={{ shrink: true, style: labelStyle, className: "label_style" }}
                                            helperText={null} />
                                    </ClickAwayListener>)
                            }} />
                    </LocalizationProvider>

                </Box>


            </div>

        );
    }

    // LIFECYCLE - This method will run immediately before a component is unmounted; any necessary cleanup ought
    // to happen in this step
    componentWillUnmount() {

    }

}

// Defining the default properties for the DSSelect component
DSSelect.defaultProps = {
    id: "ds_filter",
    options: [],
    value: [],
    filter_type: "daterange",
    editable: true,
    clearable: true,
    label: ["Start year", "End year"]
};

// Defining the different property types for the DSSelect component
DSSelect.propTypes = {

    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * The type of the filter
     */
    options: PropTypes.array,

    /**
     * This is the label of the filter
     */
    label: PropTypes.array,

    /**
     * The currently selected options for the filter
     */
    value: PropTypes.array,

    /**
     * The type of the filter
     */
    filter_type: PropTypes.string,

    /**
     * Whether or not this filter is editable
     */
    editable: PropTypes.bool,

    /**
     * Whether or not this filter is clearable
     */
    clearable: PropTypes.bool,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,
};

// Exporting the defaultProps and propTypes objects for use in the React-Dash bridge
export const defaultProps = DSSelect.defaultProps;
export const propTypes = DSSelect.propTypes;