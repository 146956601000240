// Various import statements that I'll use throughout the component
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import "./style.css"
import DSDateRange from "./DSDateRange.react.js"
import DSSelectHooks from "./DSSelectHooks.js"
import DSMultiselectHooks from "./DSMultiselectHooks.js"
import { muiTheme } from "./themeManagement.js"
import { ThemeProvider } from '@mui/material/styles';

/**
 * DSFilter is a multi-purpose filtering component.
 */
export default class DSFilter extends Component {

    // LIFECYCLE - The constructor will set up pieces of the component before it's mounted into the DOM
    constructor(props) {

        // Run the constructor of the general Component
        super(props)

    }

    // LIFECYCLE - This method will determine whether or not the component should update (according to the different
    // props / state that it'll have after the next update)
    shouldComponentUpdate(nextProps, nextState) {

        // By default, we're going to just return "true" - this will allow the component to render normally. If we
        // realize that we need more specialized behavior, then we'll add it. For now, though, this works.
        return true

    }

    // This is the ComponentDidUpdate() method - it'll run whenever the DSFilter props change
    componentDidUpdate(prevProps) {

        if (prevProps.options !== this.props.options) {
            this.props.setProps({options: this.props.options})
        }

    }

    // LIFECYCLE - The render method determines what'll be rendered by the DSFilter component
    render() {

        return (
            <>
                {/* Wrap the entire app within a ThemeProvider, using the theme defined in themeManagement.js*/}
                <ThemeProvider theme={muiTheme()}>

                    {/* Select the proper component to display based on the filter_type */}
                    {this.props.filter_type === "daterange" && <DSDateRange {...this.props}/>}
                    {this.props.filter_type === "select" && <DSSelectHooks {...this.props}/>}
                    {this.props.filter_type === "multiselect" && <DSMultiselectHooks {...this.props}/>}

                </ThemeProvider>
            </>
        );
    }
}

// Defining the default properties for the DSFilter component
DSFilter.defaultProps = {
    id: "ds_filter",
    label: "",
    options: [],
    filter_type: "select",
    disabled: false,
    clearable: true,
    clear_button_n_clicks: 0,
    label_limit: 60,
    summary: true,
    tag_limit: 2,
    min_selectable_year: 1900,
    max_selectable_year: 2099,
    summary_threshold: 1,
    style: {width: 300, height: 55, value_style: {}, label_style: {}},
    optionLoadDebounceTime: 0,
    valueChangeDebounceTime: 0,
};

// Defining the different property types for the DSFilter component
DSFilter.propTypes = {

    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * This is the label of the filter
     */
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),

    /**
     * The type of the filter
     */
    options: PropTypes.array,

    /**
     * The currently selected options for the filter
     */
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),

    /**
     * The type of the filter
     */
    filter_type: PropTypes.string,

    /**
     * Whether or not this filter is editable
     */
    disabled: PropTypes.bool,

    /**
     * Whether or not this filter is clearable
     */
    clearable: PropTypes.bool,

    /**
     * How many times the clear button has been clicked
     */
    clear_button_n_clicks: PropTypes.number,

    /**
     * The label character limit; any labels longer than this number will be truncated
     */
    label_limit: PropTypes.number,

    /**
     * Whether or not a multiselect will summarize the selected inputs
     */
    summary: PropTypes.bool,

    /**
     * Whether or not a multiselect will summarize the selected inputs
     */
    summary_threshold: PropTypes.number,

    /**
     * How many tags can be shown at once in a Multiselect filter
     */
    tag_limit: PropTypes.number,

    /**
     * If you're using a DateRange filter, this is the initial start date
     */
    start_date: PropTypes.number,

    /**
     * If you're using a DateRange filter, this is the initial end date
     */
    end_date: PropTypes.number,

    /**
     * If you're using a DateRange filter, this is the smallest year that can be selected
     */
    min_selectable_year: PropTypes.number,

    /**
     * If you're using a DateRange filter, this is the largest year that can be selected
     */
    max_selectable_year: PropTypes.number,

    /**
     * Various styling elements that're applied to the filter component
     */
    style: PropTypes.object,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func,

    /**
     * How long to debounce the Autocomplete suggestions (in milliseconds); 0 by default.
     */
    optionLoadDebounceTime: PropTypes.number,

    /**
     * How long to debounce a change in the value of the filter; 0 by default
     */
    valueChangeDebounceTime: PropTypes.number,

    /**
     * How we'll be able to programmatically update the value
     */
    valueUpdate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
};

// Exporting the defaultProps and propTypes objects for use in the React-Dash bridge
export const defaultProps = DSFilter.defaultProps;
export const propTypes = DSFilter.propTypes;